
import { defineComponent } from "vue";
import config from "@/config.json";

export default defineComponent({
  setup: () => {
    return {
      XVIposterUrl: config.XVIposterUrl,
      XVIIposterUrl: config.XVIIposterUrl,
      XVIIIposterUrl: config.XVIIIposterUrl,
      XIXposterUrl: config.XIXposterUrl,
      XXposterUrl: config.XXposterUrl,
      XXIposterUrl: config.XXIposterUrl,
      XXIIposterUrl: config.XXIIposterUrl,
      XXIIIposterUrl: config.XXIIIposterUrl,
      XXIIIposterUrla: config.XXIIIposterUrla,
      XXIVposterUrl: config.XXIVposterUrl,
      XXVposterUrl: config.XXVposterUrl,
      XXVIposterUrl: config.XXVIposterUrl,
      XXVIIposterUrl: config.XXVIIposterUrl,
      XXVIIIposterUrl: config.XXVIIIposterUrl,
      XXIXposterUrl: config.XXIXposterUrl,
    };
  },
});
