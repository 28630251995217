import { Participant, Institute } from "@/types/index";

export const ZHIQIN_LU: Participant = {
  name: "Zhiqin Lu",
  link: "mailto:zlu@uci.edu",
  institute: Institute.UC_IRVINE,
};

export const LEI_NI: Participant = {
  name: "Lei Ni",
  link: "mailto:lni@math.ucsd.edu",
  institute: Institute.UC_SAN_DIEGO,
};

export const RICHARD_SCHOEN: Participant = {
  name: "Richard Schoen",
  link: "mailto:rschoen@math.uci.edu",
  institute: Institute.UC_IRVINE,
};

export const JEFF_STREETS: Participant = {
  name: "Jeff Streets",
  link: "mailto:jstreets@uci.edu",
  institute: Institute.UC_IRVINE,
};

export const LISHENG_TSENG: Participant = {
  name: "Li-sheng Tseng",
  link: "mailto:lstseng@math.uci.edu",
  institute: Institute.UC_IRVINE,
};

export const JEFF_VIACLOVSKY: Participant = {
  name: "Jeff Viaclovsky",
  link: "mailto:jviaclovs@uci.edu",
  institute: Institute.UC_IRVINE,
};

export const XIANGWEN_ZHANG: Participant = {
  name: "Xiangwen Zhang",
  link: "mailto:xiangwen@uci.edu",
  institute: Institute.UC_IRVINE,
};

export const YI_LAI: Participant = {
  name: "Yi Lai",
  link: "mailto:yilai@stanford.edu",
  institute: Institute.UC_IRVINE,
};

export const SIMON_BRENDLE: Participant = {
  name: "Simon Brendle",
  link: "mailto:sab2280@columbia.edu",
  institute: Institute.COLUMBIA,
};

export const LANHSUAN_HUANG: Participant = {
  name: "Lan-Hsuan Huang",
  link: "mailto:lan-hsuan.huang@uconn.edu",
  institute: Institute.UNIVERSITY_OF_CONNECTICUT,
};

export const PENGFEI_GUAN: Participant = {
  name: "Pengfei Guan",
  link: "mailto:pengfei.guan@mcgill.ca",
  institute: Institute.MCGILL,
};

export const RYAN_UNGER: Participant = {
  name: "Ryan Unger",
  link: "mailto:runger@stanford.edu",
  institute: Institute.STANFORD,
};
